import * as React from "react";
import Layout from "../components/layout";

const Loader = () => {
  const circleCommonClasses = "h-2.5 w-2.5 bg-current rounded-full";

  return (
    <div className="flex justify-center">
      <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
      <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
      <div className={`${circleCommonClasses} animate-bounce400`}></div>
    </div>
  );
};

const ContactUsPage = () => {
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [sending, setSending] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [sendError, setSendError] = React.useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    const name = e.target.name.value;
    const email = e.target.email.value;
    const topic = e.target.topic.value;
    const message = e.target.message.value;

    var newFieldErrors = {
      nameRequired: name == null || name.trim().length === 0,
      emailRequired: email == null || email.trim().length === 0,
      topicRequired: topic == null || topic.trim().length === 0,
      messageRequired: message == null || message.trim().length === 0,
    };
    setFieldErrors(newFieldErrors);

    if (Object.values(newFieldErrors).find((x) => x) != null) {
      return;
    }

    const contactUsUrl =
      "https://kvbkyphvr3.execute-api.ap-southeast-2.amazonaws.com/default/sendContactEmail";
    try {
      setSending(true);
      setSendError(false);

      await fetch(contactUsUrl, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        mode: "no-cors",
        body: JSON.stringify({
          name,
          email,
          topic,
          message,
        }),
      });
      setSent(true);
    } catch {
      setSendError(true);
    } finally {
      setSending(false);
    }
  };

  return (
    <Layout title="Contact Us - SquareMeter">
      <div className="max-w-screen-xl mx-auto px-5 md:p-24">
        {sent && (
          <>
            <h1 className="text-4xl py-10">Thanks for reaching out!</h1>

            <p className="text-lg py-1">
              We will look over your message and get back to you soon.
            </p>
            <p className="text-lg py-1">
              Our inbox can't wait to get your messages, so talk to us any time
              you like.
            </p>
            <p className="text-md pt-8">Your friends at SquareMeter</p>
          </>
        )}
        {!sent && (
          <>
            <h1 className="text-4xl py-5 pt-10 md:py-10">Contact Us</h1>

            <form className="w-full" onSubmit={onSubmit} autoComplete="on">
              <div className="md:my-2">
                <label
                  htmlFor="contact-us-your-name"
                  className="block py-3 font-bold"
                  aria-required
                >
                  Your name *
                </label>
                <input
                  type="text"
                  id="contact-us-your-name"
                  name="name"
                  autoComplete="name"
                  className={
                    "w-full p-2 border border-gray-400 rounded-md" +
                    (fieldErrors.nameRequired ? " border-red-400" : "")
                  }
                  disabled={sending}
                />
                <span
                  className="text-red-400"
                  style={{
                    display: fieldErrors.nameRequired ? "block" : "none",
                  }}
                >
                  Provide your full name, so we know who the message is from.
                </span>
              </div>

              <div className="md:my-2">
                <label
                  htmlFor="contact-us-your-email"
                  className="block py-3 font-bold"
                  aria-required
                >
                  Your email *
                </label>
                <input
                  type="email"
                  id="contact-us-your-email"
                  name="email"
                  autoComplete="email"
                  className={
                    "w-full p-2 border border-gray-400 rounded-md" +
                    (fieldErrors.emailRequired ? " border-red-400" : "")
                  }
                  disabled={sending}
                />
                <span
                  className="text-red-400"
                  style={{
                    display: fieldErrors.emailRequired ? "block" : "none",
                  }}
                >
                  Provide your email, in case we need to contact you.
                </span>
              </div>

              <div className="md:my-2">
                <label
                  htmlFor="contact-us-topic"
                  className="block py-3 font-bold"
                  aria-required
                >
                  Topic *
                </label>
                <select
                  id="contact-us-topic"
                  name="topic"
                  className={
                    "w-full p-2 border border-gray-400 rounded-md" +
                    (fieldErrors.topicRequired ? " border-red-400" : "")
                  }
                  disabled={sending}
                >
                  <option value="">Select a topic&hellip;</option>
                  <option value="general-enquiry">General enquiry</option>
                  <option value="feedback">Feedback for the team</option>
                  <option value="seller-account">
                    I want a seller account
                  </option>
                </select>
                <span
                  className="text-red-400"
                  style={{
                    display: fieldErrors.topicRequired ? "block" : "none",
                  }}
                >
                  Select a topic, to help us categorise the message.
                </span>
              </div>

              <div className="md:my-2">
                <label
                  htmlFor="contact-us-message"
                  className="block py-3 font-bold"
                  aria-required
                >
                  Message *
                </label>
                <textarea
                  id="contact-us-message"
                  name="message"
                  rows="5"
                  className={
                    "w-full p-2 border border-gray-400 rounded-md" +
                    (fieldErrors.messageRequired ? " border-red-400" : "")
                  }
                  disabled={sending}
                ></textarea>
                <span
                  className="text-red-400"
                  style={{
                    display: fieldErrors.messageRequired ? "block" : "none",
                  }}
                >
                  Oops! You forgot to type a message to the team.
                </span>
              </div>

              {sendError && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Oops!</strong>
                  <span className="block sm:inline pl-1">
                    Something unexpected went wrong. Sorry about that, please
                    try again.
                  </span>
                </div>
              )}

              <div className="my-5">
                <button
                  type="submit"
                  className={
                    "bg-gray-500 text-white p-3 w-full max-w-xs focus:bg-gray-300" +
                    (sending ? " cursor-default" : "")
                  }
                  disabled={sending}
                >
                  {sending && <Loader />}
                  {!sending && <>Submit</>}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ContactUsPage;
